@tailwind base;
@tailwind components;

html,
button,
input,
textarea,
select {
  text-transform: uppercase;
}

.paddingCapture {
  padding-top: 8rem;
}

.text-input {
  @apply w-full border border-gray-300 rounded p-1 focus:outline-none focus:ring-2 focus:ring-blue-300;
}

.btn-secondary {
  @apply w-1/3 bg-blue-600 hover:bg-blue-700 transition duration-300 rounded-md p-1 shadow text-white;
}

.btn-success {
  @apply w-1/3 bg-green-500 hover:bg-green-600 transition duration-300 rounded-md p-1 shadow text-white;
}

.btn-purple {
  @apply w-1/3 bg-purple-500 hover:bg-purple-600 transition duration-300 rounded-md p-1 shadow text-white;
}

.btn-red {
  @apply w-1/3 bg-red-500 hover:bg-red-600 transition duration-300 rounded-md p-1 shadow text-white;
}

.btn-orange {
  @apply w-1/3 bg-yellow-500 hover:bg-yellow-600 transition duration-300 rounded-md p-1 shadow text-white;
}
.btn-gray {
  @apply w-1/3 bg-gray-500 hover:bg-gray-600 transition duration-300 rounded-md p-1 shadow text-white;
}

.card {
  @apply rounded-md shadow-lg py-2 px-3;
}

.nav-item {
  @apply py-4 px-2 font-semibold hover:text-blue-500 transition duration-300 cursor-pointer;
}

.nav-item.active-place {
  @apply py-4 px-2 text-blue-500 border-b-4 border-blue-500 font-semibold cursor-pointer;
}

.nav-item-mobile {
  @apply py-4 px-2 font-semibold hover:text-blue-500 transition duration-300 cursor-pointer;
}

.box-quickacc {
  @apply cursor-pointer;
}

.header-quickacc {
  @apply text-xl text-white block bg-red-500 text-center rounded-t-lg;
}

.body-quickacc {
  @apply text-center bg-gray-100 border border-gray-200 rounded-b-lg;
}

.image-quickacc {
  @apply mx-auto w-4/5 h-4/5;
}

.label-activo,
.label-aprobado {
  @apply bg-green-100 rounded-full py-1 px-3 text-green-700;
}

.label-cerrado,
.label-pendiente {
  @apply bg-yellow-100 rounded-full py-1 px-3 text-yellow-700;
}

.label-cancelado {
  @apply bg-red-100 rounded-full py-1 px-3 text-red-700;
}

.label-rechazado {
  @apply bg-purple-100 rounded-full py-1 px-3 text-purple-700;
}

.label-mobile-activo,
.label-mobile-aprobado {
  @apply bg-green-100 border-2 border-green-200;
}

.label-mobile-cerrado,
.label-mobile-pendiente {
  @apply bg-yellow-100 border-2 border-yellow-200;
}

.label-mobile-cancelado {
  @apply bg-red-100 border-2 border-red-200;
}

.label-mobile-rechazado {
  @apply bg-purple-100 border-2 border-purple-200;
}

.button-view {
  @apply border border-gray-200  w-1/2;
}

.active-view {
  @apply text-white bg-blue-500;
}

.deactive-view {
  @apply text-blue-700 bg-gray-100;
}

.modal-back {
  @apply fixed bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full;
}

.modal-box {
  @apply relative top-20 mx-auto shadow-lg rounded-md w-full md:w-1/2 bg-white py-3 px-4;
}

.modal-title {
  @apply block text-xl text-center font-semibold border-b border-gray-100 pb-2;
}

.toggle-active {
  @apply bg-green-400 transform translate-x-full;
}

.toggle-regular {
  @apply bg-white border border-gray-300;
}

.react-time-picker__wrapper {
  border: none !important;
}

/*Remove Arrows from Numbers*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
/*Remove Arrows from Numbers*/

@tailwind utilities;
